import { generateGoogleMapUrl, GOOGLE_MAPS_API_KEY } from '@flock/shared-ui'
import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  OfferPortfolioProps,
  OfferPortfolioPresentationalProps,
} from './offerPortfolioTypes'

const useOfferPortfolio: (
  props: OfferPortfolioProps
) => OfferPortfolioPresentationalProps = (props: OfferPortfolioProps) => {
  const { street, cityStateZip, condition } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const desktopConfig = {
    containerStyle: {
      height: '272px',
      borderRadius: '8px',
    },
    center: [-94.9903, 37.7392],
    startingZoom: 2.5,
  }
  const mobileConfig = {
    containerStyle: {
      height: '200px',
      borderRadius: '8px',
    },
    center: [-94.9903, 37.7392],
    startingZoom: 0,
  }

  const [streetViewUrl, setStreetViewUrl] = useState<string>()

  useEffect(() => {
    const streetviewMetadataUrl = generateGoogleMapUrl({
      type: 'streetview/metadata',
      location: `${street} ${cityStateZip}`,
      key: GOOGLE_MAPS_API_KEY,
    })

    fetch(streetviewMetadataUrl)
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        if (resultData.status === 'OK') {
          setStreetViewUrl(
            generateGoogleMapUrl({
              type: 'streetview',
              location: `${street} ${cityStateZip}`,
              size: isMobile ? '136x136' : '248x248',
              key: GOOGLE_MAPS_API_KEY,
              fov: 80,
            })
          )
        }
      })
  }, [street, cityStateZip, isMobile])

  let homeCondition
  switch (condition) {
    case '5 - pristine':
      homeCondition = 'Near perfect'
      break
    case '4 - great':
      homeCondition = 'Normal wear and tear'
      break
    case '3 - average':
      homeCondition = 'Needs minor repairs'
      break
    case '2 - below average':
      homeCondition = 'Needs major work'
      break
    case '1 - requires complete renovation':
      homeCondition = 'Not habitable'
      break
    default:
      homeCondition = ''
      break
  }

  return {
    ...props,
    isMobile,
    isTablet,

    homeCondition,
    streetViewUrl,

    mobileConfig,
    desktopConfig,
  }
}

export default useOfferPortfolio
