import {
  Box,
  Tab,
  Tabs,
  Typography,
  SvgIconProps,
  useTheme,
  useMediaQuery,
  Paper,
} from '@mui/material'
import React from 'react'
import slugify from 'slugify'
import { TrackingContextProvider } from '../TrackingContextProvider'
import { TrackingConfig, useTracking } from '../useTracking'

export type OmSidebarWithIconsItem = {
  title: string
  unselectedIcon: React.FC<SvgIconProps>
  selectedIcon: React.FC<SvgIconProps>
  ref?: React.RefObject<HTMLDivElement>
  hide?: boolean
}

type OmSidebarWithIconsProps = {
  items: OmSidebarWithIconsItem[]
  selected: number
  trackingConfig?: TrackingConfig
  onChange: (selected: number) => void
  iconColor?: string
  textColor?: string
  selectedColor?: string
}

const OmSidebarWithIcons = (props: OmSidebarWithIconsProps) => {
  const {
    items,
    selected,
    trackingConfig,
    onChange,
    iconColor,
    textColor,
    selectedColor,
  } = props
  const { track } = useTracking()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    onChange(newValue)
    track(slugify(items[newValue].title.toLowerCase()))
    if (items[newValue].ref) {
      items[newValue].ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <TrackingContextProvider name={trackingConfig?.name || 'icon-sidebar'}>
      {isTablet ? (
        <Tabs
          orientation="vertical"
          value={selected}
          onChange={handleChange}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {items.map((item, idx) => {
            const {
              title,
              unselectedIcon: UnselectedIcon,
              selectedIcon: SelectedIcon,
              hide,
            } = item
            const isSelected = selected === idx
            return (
              <Tab
                key={title}
                sx={{
                  display: hide ? 'none' : 'flex',
                  alignContent: 'flex-start',
                  flexWrap: 'wrap',
                  pt: '16px',
                  pb: '16px',
                }}
                label={
                  <Box
                    display="flex"
                    alignContent="flex-start"
                    pl="0px"
                    gap="16px"
                  >
                    <Box>
                      {isSelected ? (
                        <SelectedIcon
                          width="32px"
                          height="32px"
                          // @ts-ignore
                          color={iconColor}
                        />
                      ) : (
                        <UnselectedIcon width="32px" height="32px" />
                      )}
                    </Box>
                    {!isTablet && (
                      <Typography
                        variant="h3"
                        textAlign="left"
                        sx={{
                          fontSize: '24px',
                          lineHeight: '32px',
                          fontWeight: isSelected ? 800 : 500,
                          '&:hover': {
                            fontWeight: 800,
                            color: textColor
                              ? `${textColor}!important`
                              : undefined,
                          },
                          color:
                            textColor && isSelected
                              ? `${textColor}!important`
                              : undefined,
                        }}
                      >
                        {title}
                      </Typography>
                    )}
                  </Box>
                }
              />
            )
          })}
        </Tabs>
      ) : (
        <Box display="flex" flexDirection="column" width="100%" gap={1}>
          {items.map((item, idx) => {
            const { title, hide } = item
            const isSelected = selected === idx

            return (
              <Paper
                key={title}
                sx={{
                  display: hide ? 'none' : 'flex',
                  padding: '16px',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                  borderRadius: '16px',
                  boxShadow: 'none',
                  backgroundColor: isSelected
                    ? selectedColor || 'mulberry.main'
                    : 'gray1.main',
                  transition: 'background-color 0.2s ease-in-out',
                  cursor: 'pointer',
                }}
                // @ts-ignore
                onClick={() => handleChange(null, idx)}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Typography
                    variant="p1"
                    fontWeight={isSelected ? 500 : 400}
                    color={isSelected ? 'white.main' : undefined}
                  >
                    {title}
                  </Typography>
                </Box>
              </Paper>
            )
          })}
        </Box>
      )}
    </TrackingContextProvider>
  )
}

export default OmSidebarWithIcons

OmSidebarWithIcons.defaultProps = {
  trackingConfig: null,
  iconColor: null,
  textColor: null,
  selectedColor: null,
}
