import { createContext } from 'react'
import { User } from '../types'

export type OfferPageContextType = {
  user?: User
  calendlyLink?: string
  salesAssigneeName?: string
}

const defaultContext = {
  user: undefined,
  calendlylink: '',
  salesAssigneeName: '',
}

const OfferPageContext = createContext<OfferPageContextType>(defaultContext)

export const OfferPageContextProvider = OfferPageContext.Provider
export default OfferPageContext
