import { Paper } from '@mui/material'
import React from 'react'

type OfferPagePaperProps = {
  children: React.ReactNode
  backgroundColor?: string
  horizontalPadding?: string
  verticalPadding?: string
  borderRadius?: string
}

const OfferPagePaper = (props: OfferPagePaperProps) => {
  const {
    children,
    backgroundColor,
    horizontalPadding,
    verticalPadding,
    borderRadius,
  } = props
  return (
    <Paper
      sx={{
        py: {
          xs: verticalPadding || '20px',
          sm: verticalPadding || '24px',
        },
        px: {
          xs: horizontalPadding || '20px',
          sm: horizontalPadding || '24px',
        },
        backgroundColor,
        borderRadius: borderRadius || '24px',
        maxWidth: '544px',
      }}
    >
      {children}
    </Paper>
  )
}

OfferPagePaper.defaultProps = {
  backgroundColor: 'white',
  verticalPadding: '',
  horizontalPadding: '',
  borderRadius: '',
}

export default OfferPagePaper
