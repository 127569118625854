import { useMediaQuery, useTheme } from '@mui/material'

/**
 * UI Logic Layer
 * Handles all of the logic for anything that can change what's presented
 * on the screen so that the presentational components can be as simple as
 * possible.
 */
const useGenericPrelimHero = (props: GenericPrelimHeroProps) => {
  // Props decomposition
  const { fullName, leadNameOverride } = props

  // Presentational layout hooks
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  return {
    fullName,
    leadNameOverride,

    isMobile,
    isTablet,
  }
}

export default useGenericPrelimHero
