import React from 'react'
import GenericPrelimImportantResourcesMobile from './GenericPrelimImportantResourcesMobile'
import GenericPrelimImportantResourcesTablet from './GenericPrelimImportantResourcesTablet'
import GenericPrelimImportantResourcesDesktop from './GenericPrelimImportantResourcesDesktop'
import useGenericPrelimImportantResources from './useGenericPrelimImportantResources'

const GenericPrelimImportantResources = (
  props: GenericPrelimImportantResourcesProps
) => {
  const presentationalProps = useGenericPrelimImportantResources(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <GenericPrelimImportantResourcesMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <GenericPrelimImportantResourcesTablet {...presentationalProps} />
  }
  return <GenericPrelimImportantResourcesDesktop {...presentationalProps} />
}

export default GenericPrelimImportantResources
