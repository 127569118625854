/* eslint no-underscore-dangle: 0 */

import { useQuery } from '@apollo/client'
import {
  Core_GetStaticDocumentsResponse,
  Core_LeadDocument,
  Core_StaticDocument,
  LandingGetStaticDocumentsDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { GENERIC_OFFER_PAGE_DOCUMENTS } from '../../../constants'
import {
  OfferDocumentsPresentationalProps,
  OfferDocumentsProps,
} from './offerDocumentsTypes'

enum LeadDocumentType {
  ADDITIONAL_OFFER_DETAILS = 'additional-offer-details',
  EDUCATIONAL = 'educational',
  PERFORMANCE = 'performance',
  LEGAL = 'legal',
  OTHER = 'other',
}

type AnyDocument = Core_LeadDocument | Core_StaticDocument

type DocumentConfig = {
  title: string
  documents: AnyDocument[]
}

type DocumentConfigs = {
  [key in LeadDocumentType]: DocumentConfig
}

const displayOrder = [
  LeadDocumentType.ADDITIONAL_OFFER_DETAILS,
  LeadDocumentType.EDUCATIONAL,
  LeadDocumentType.PERFORMANCE,
  LeadDocumentType.LEGAL,
  LeadDocumentType.OTHER,
]

const genericDocumentOrder = [
  'Flock Homes Overview',
  'Trustpilot Reviews',
  'Business Insider Article',
  'Denver Post Article',
  'Andreessen Horowitz Article',
  'Primary Venture Partners Article',
  'Susa Ventures Article',
  'Fact Sheet',
  'Investor Call',
  'Fund Financials',
  'KPMG Audit',
  'Flock Private Placement Memorandum',
  'Flock Homes Limited Partnership Agreement',
]

function compareDocuments(a: AnyDocument, b: AnyDocument) {
  const aIndex = genericDocumentOrder.indexOf(a.name!)
  const bIndex = genericDocumentOrder.indexOf(b.name!)
  return aIndex - bIndex
}

const useOfferDocuments: (
  props: OfferDocumentsProps
) => OfferDocumentsPresentationalProps = (props: OfferDocumentsProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const { leadDocuments, staticDocumentOverrides, isGenericOfferPage } = props

  const isSSR = typeof window === 'undefined'

  const [documentConfigs, setDocumentConfigs] = useState<DocumentConfig[]>([])

  const { loading } = useQuery(LandingGetStaticDocumentsDocument, {
    onCompleted: (result: any) => {
      const response =
        result.getStaticDocuments as Core_GetStaticDocumentsResponse
      const staticDocuments = (response.staticDocuments ||
        []) as Core_StaticDocument[]

      const newDocumentConfigMap: DocumentConfigs = {
        'additional-offer-details': {
          title: 'Additional Offer Details',
          documents: [],
        },
        educational: {
          title: 'Educational',
          documents: [],
        },
        performance: {
          title: 'Performance',
          documents: [],
        },
        legal: {
          title: 'Legal',
          documents: [],
        },
        other: {
          title: 'Other',
          documents: [],
        },
      } as any

      let allDocuments: AnyDocument[] = leadDocuments

      allDocuments = allDocuments.concat(staticDocuments)
      if (isGenericOfferPage) {
        allDocuments = allDocuments.concat(GENERIC_OFFER_PAGE_DOCUMENTS)

        //  Order appropriately
        allDocuments = allDocuments.sort(compareDocuments)
      }

      const genericOfferPageDocumentsNames = GENERIC_OFFER_PAGE_DOCUMENTS.map(
        (document) => document.name
      )
      allDocuments.forEach((document) => {
        Object.values(LeadDocumentType).forEach((documentType) => {
          if (document.documentType === documentType) {
            if (document.__typename === 'Core_StaticDocument') {
              // If this is a static document, check if we've overridden the display value. If no
              // override was explicitly set, just use the default value.
              if (
                staticDocumentOverrides[document.uuid] === true ||
                (staticDocumentOverrides[document.uuid] !== false &&
                  (document as Core_StaticDocument).defaultEnabled)
              ) {
                // Check for duplicates accross generic documents and document overrides
                if (
                  !isGenericOfferPage ||
                  !genericOfferPageDocumentsNames.includes(document.name)
                ) {
                  newDocumentConfigMap[
                    documentType as LeadDocumentType
                  ].documents.push(document)
                }
              }
            } else {
              // We show all lead documents.
              newDocumentConfigMap[
                documentType as LeadDocumentType
              ].documents.push(document)
            }
          }
        })
      })

      // Enforce an order
      const orderedDocumentConfigs = displayOrder.map(
        (documentType) => newDocumentConfigMap[documentType]
      )

      setDocumentConfigs(orderedDocumentConfigs)
    },
  })

  return {
    ...props,
    isMobile,
    isTablet,

    isSSR,
    documentConfigs,
    loading,
  }
}

export default useOfferDocuments
