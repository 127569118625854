import React from 'react'
import GenericPrelimHeroDesktop from './GenericPrelimHeroDesktop'
import GenericPrelimHeroTablet from './GenericPrelimHeroTablet'
import GenericPrelimHeroMobile from './GenericPrelimHeroMobile'
import useGenericPrelimHero from './useGenericPrelimHero'

const GenericPrelimHero = (props: GenericPrelimHeroProps) => {
  const presentationalProps = useGenericPrelimHero(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <GenericPrelimHeroMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <GenericPrelimHeroTablet {...presentationalProps} />
  }
  return <GenericPrelimHeroDesktop {...presentationalProps} />
}

export default GenericPrelimHero
