import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const StarOutlinedIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M56.7564 22.0688L40.8876 19.7626L33.7939 5.38132C33.6001 4.98757 33.2814 4.66882 32.8876 4.47507C31.9001 3.98757 30.7001 4.39382 30.2064 5.38132L23.1126 19.7626L7.24387 22.0688C6.80637 22.1313 6.40637 22.3376 6.10012 22.6501C5.72988 23.0306 5.52586 23.5426 5.53289 24.0735C5.53992 24.6043 5.75743 25.1107 6.13762 25.4813L17.6189 36.6751L14.9064 52.4813C14.8428 52.849 14.8834 53.2272 15.0238 53.5729C15.1642 53.9186 15.3986 54.2181 15.7005 54.4374C16.0025 54.6567 16.3598 54.787 16.732 54.8135C17.1042 54.8401 17.4764 54.7618 17.8064 54.5876L32.0001 47.1251L46.1939 54.5876C46.5814 54.7938 47.0314 54.8626 47.4626 54.7876C48.5501 54.6001 49.2814 53.5688 49.0939 52.4813L46.3814 36.6751L57.8626 25.4813C58.1751 25.1751 58.3814 24.7751 58.4439 24.3376C58.6126 23.2438 57.8501 22.2313 56.7564 22.0688ZM41.5501 35.1001L43.8064 48.2438L32.0001 42.0438L20.1939 48.2501L22.4501 35.1063L12.9001 25.7938L26.1001 23.8751L32.0001 11.9188L37.9001 23.8751L51.1001 25.7938L41.5501 35.1001Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default StarOutlinedIcon
