import { useMediaQuery, useTheme } from '@mui/material'

const useGenericPrelimImportantResources = (
  props: GenericPrelimImportantResourcesProps
) => {
  // Presentational layout hooks
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  return {
    isMobile,
    isTablet,
    ...props,
  }
}

export default useGenericPrelimImportantResources
