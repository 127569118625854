import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const MapFilledIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M3.94981 6.2555L12.0133 4L20.1004 6.27803L27.621 4.14462C27.7821 4.0989 27.9517 4.09117 28.1163 4.12205C28.281 4.15292 28.4362 4.22155 28.5699 4.32255C28.7035 4.42355 28.8119 4.55417 28.8865 4.70413C28.9612 4.8541 29 5.01933 29 5.18685V23.636C28.9992 23.9187 28.9066 24.1935 28.7361 24.4191C28.5657 24.6446 28.3266 24.8087 28.0548 24.8867L20.1023 27.1427L12.0117 24.8637L4.37507 26.9998C4.21406 27.0448 4.04482 27.052 3.88059 27.0207C3.71635 26.9894 3.56159 26.9205 3.42842 26.8195C3.29524 26.7184 3.18726 26.5879 3.11294 26.4381C3.03861 26.2884 2.99996 26.1234 3 25.9562V7.50745C3.00071 7.22389 3.09381 6.94829 3.2652 6.72238C3.43659 6.49647 3.67692 6.33257 3.94981 6.2555ZM12.8792 23.307L19.3042 25.1168V7.8545L12.8792 6.04468V23.307Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default MapFilledIcon
